<template>
  <div class="py-40 md:py-56 products-block">
    <ClientOnly>
      <div v-if="isEditMode" class="layout-container mb-24">
        <div
          class="btn btn--dark-theme mr-16 rounded-none px-12"
          data-epi-edit="LayoutType"
        >
          Change layout
        </div>
      </div>
    </ClientOnly>
    <div
      v-if="block.Filters?.ShowFilter || block.ButtonLinks && block.ButtonLinks?.length"
      class="layout-container pt-40 pb-16"
    >
      <div class="flex items-center flex-wrap mt-24 md:mt-48">
        <div class="flex flex-wrap w-full">
          <ClientOnly>
            <swiper
              v-if="block.ButtonLinks?.length"
              :slides-per-view="'auto'"
              :breakpoints="{
                '1024': {
                  simulateTouch: false,
                  allowTouchMove: false
                },
              }"
              class="flex mb-20 filter-swiper"
            >
              <swiper-slide>
                <nuxt-link
                  v-for="(link, index) in block.ButtonLinks"
                  :key="index"
                  :to="link?.Href"
                  class="swiper-slide transition duration-300 ease-in-out bg-grey400 text-base font-medium px-20 py-4 mr-12 leading-none text-center w-auto flex items-center justify-center hover:bg-grey500 lg:h-[calc(100%-4px)]"
                >
                  {{ link?.Text }}
                </nuxt-link>
              </swiper-slide>
            </swiper>
          </ClientOnly>
          <div
            v-if="block.Filters?.ShowFilter"
            class="filters flex-wrap md:flex-nowrap md:ml-auto flex md:items-center md:justify-center w-full md:w-auto mb-24 md:md-0"
          >
            <div
              class="w-full md:w-auto transition duration-300 ease-in-out cursor-pointer border border-black py-8 px-20 rounded-sm base-medium-text mb-12 md:mb-0 md:mr-12 md:hover:bg-black md:hover:text-white"
              @click="toggleFilter()"
            >
              <ClientOnly><fa :icon="['fal', 'sliders-h']" class="mr-4"></fa></ClientOnly>
              {{ $lang('productListingResources', 'moreFilters') }}
            </div>

            <div class="relative w-full md:w-auto">
              <ClientOnly>
                <fa
                  :icon="['far', 'chevron-down']"
                  class="ml-4 absolute right-0 top-0 mr-12 mt-12"
                ></fa>
              </ClientOnly>
              <select
                v-model="sortBy"
                class="select-sort-by w-full pr-32 appearance-none text-center bg-transparent relative transition duration-300 ease-in-out cursor-pointer border border-black py-8 px-20 rounded-sm base-medium-text md:hover:bg-black md:hover:text-white"
                @change="fetchProducts(false)"
              >
                <option value="">
                  {{ $lang('productListingResources', 'sortBy') }}
                </option>

                <option value="popularity">
                  {{ $lang('productListingResources', 'sortByPopularity') }}
                </option>
                <option value="price">
                  {{ $lang('productListingResources', 'sortByPrice') }}
                </option>
                <option value="price desc">
                  {{ $lang('productListingResources', 'sortByPriceDesc') }}
                </option>
                <option value="news">
                  {{ $lang('productListingResources', 'sortByNews') }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.LinkUrl || data.Heading" class="layout-container">
      <div class="flex justify-between mb-24 md:mr-16 md:px-0 min-h-[30px]">
        <h5
          v-if="data.Heading"
          class="sub-Heading-sm-text md:text-md pr-12 font-thunder"
          :style="`color: ${textColor}`"
        >
          {{ data.Heading }}
        </h5>
        <nuxt-link
          v-if="data.LinkUrl && data.LinkText"
          :to="data.LinkUrl"
          class="small-base-text md:text-base self-end mb-2 font-thunder"
          :style="`color: ${textColor}`"
        >
          {{ data.LinkText }}
        </nuxt-link>
      </div>
    </div>
    <div
      v-if="
        data.LayoutType === 'Autoship' ||
        data.LayoutType === 'Quickbuy' ||
        data.LayoutType === 'WithoutNameCTA'
      "
      class="layout-container"
    >
      <div v-if="data.LayoutType === 'WithoutNameCTA'" class="flex flex-wrap">
        <ProductSpotSimple
          v-for="(product, index) in products"
          :key="product.id"
          :product="product"
          :use-schema-props="useSchemaProps"
          :full-width-image="data.UseBrandImage && isBrandImage(product)"
          :show-info="false"
          :show-cta="true"
          :is-sample="isSample ? isSample : false"
          :load="index < 7 ? 'eager' : 'lazy'"
        />
      </div>
      <div v-else class="flex flex-wrap">
        <ProductSpot
          v-for="(product, index) in products"
          :key="product.id"
          :product="product"
          :use-schema-props="useSchemaProps"
          :subscription="data.LayoutType === 'Autoship'"
          :load="index < 7 ? 'eager' : 'lazy'"
        />
      </div>
    </div>
    <div
      v-else-if="
        (data.LayoutType === 'Slider' ||
          data.LayoutType === 'SliderWithoutName' ||
          data.LayoutType === 'WithoutNameCTASlider' ||
          data.LayoutType === 'QuickbuySlider') &&
        products &&
        products.length
      "
      class="layout-container px-0 lg:px-56"
      :class="{
        'min-h-[600px]': data.LayoutType === 'QuickbuySlider',
        'min-h-[460px]': data.LayoutType === 'Slider',
      }"
    >
      <client-only>
        <swiper
          :modules="[Navigation, Pagination]"
          :slides-per-view="'auto'"
          :navigation="true"
          :observer="true"
          :observeParents="true"
          :auto-height="true"
          :freeMode="true"
          :pagination="{
            type: 'progressbar',
          }"
          :breakpoints="{
            '1024': {
              autoHeight: false,
              simulateTouch: false,
              allowTouchMove: false,
            },
          }"
          class="products-slider px-24"
          :class=" {
            'hide-buttons hide-pagination': isMobile || (products && products.length < 4),
          }"
        >
          <swiper-slide
            v-for="(product, index) in products"
            :key="product.id"
            class="swiper-slide flex !h-auto"
          >
            <ProductSpot
              v-if="data.LayoutType === 'QuickbuySlider'"
              :product="product"
              :use-schema-props="useSchemaProps"
              class="product-spot--slider h-full sm:!h-[calc(100%-40px)]"
              :load="index < 7 ? 'eager' : 'lazy'"
            />
            <ProductSpotSimple
              v-else
              :product="product"
              :use-schema-props="useSchemaProps"
              :full-width-image="data.UseBrandImage && isBrandImage(product)"
              :show-info="!data.LayoutType.includes('WithoutName')"
              :show-cta="data.LayoutType.includes('WithoutNameCTASlider')"
              :text-color="textColor ? textColor : ''"
              :is-sample="isSample ? isSample : false"
              class="product-spot--slider"
              :class="{
                'product-spot--brand-image': data.UseBrandImage,
              }"
              :load="index < 7 ? 'eager' : 'lazy'"
            />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
    <div
      v-else-if="
        data.LayoutType === 'List' || data.LayoutType === 'ListWithoutName'
      "
      class="layout-container px-0 sm:px-24 md:px-56"
    >
      <div class="flex flex-wrap">
        <ProductSpotSimple
          v-for="(product, index) in products"
          :key="product.id"
          :product="product"
          :is-sample="isSample ? isSample : false"
          :use-schema-props="useSchemaProps"
          :show-info="!data.LayoutType.includes('WithoutName')"
          :text-color="textColor ? textColor : ''"
          class="product-spot--list"
          :load="index < 7 ? 'eager' : 'lazy'"
        />
      </div>
    </div>
    <ProductListFilter
      v-if="block.Filters?.ShowFilters"
      :show="showFilter"
      :filters="filters"
      @close="toggleFilter"
      @toggleFilterSelected="toggleFilterSelected"
      @reset="resetFilters"
    />
  </div>
</template>
<script setup lang="ts">
import ProductListFilter from '../ProductListFilter.vue';
import ProductSpot from '../ProductSpot.vue';
import ProductSpotSimple from '../ProductSpotSimple.vue';
import type { ProductsBlockFragment } from '#gql';
import { useGlobalContentStore } from '~/store/globalContent';
import {
  type IFilter,
  type IFilterValue,
  type IProductItem,
  PurchaseType,
  type IProductItemsResult
} from '~/models/api-types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import * as Sentry from '@sentry/vue'

const config = useGlobalContentStore().config;
const { apiGet } = useApiFetch();
const { convertToRelativeUrl } = useStringExtensions();

interface IProps {
  block: ProductsBlockFragment,
  isMobile: boolean,
  isEditMode: boolean,
  isSample?: boolean,
  useSchemaProps?: boolean,
  textColor?: string
}

const props = withDefaults(defineProps<IProps>(), {
  useSchemaProps: true
});

const products: Ref<IProductItem[]> = ref([]);
const filters: Ref<IFilter[]> = ref([]);
const showFilter = ref(false);
const loading = ref(false);
const sortBy = ref('');

const data = computed(() => {
  props.block.ButtonLinks?.forEach((button) => {
      button.Href = convertToRelativeUrl(button?.Href);  
  });
  if(props.block.LinkUrl) {
    props.block.LinkUrl = convertToRelativeUrl(props.block.LinkUrl);
  }
  return props.block as ProductsBlockFragment;
});

const productUrls = computed(() => {
  let productString = '';
  if (data.value.Products.length && !data.value.Brands?.length) {
    for (let i = 0; i < data.value.Products.length; i++) {
      if (!data.value?.Products[i]?.Expanded?.UniqueName) {
        continue;
      }
      productString += '&uniqueUrls=' +data.value.Products[i]?.Expanded?.UniqueName;
    }
  }
  return productString;
});

const shouldRenderServerSide = computed(() => {
  return data.value?.LayoutType === 'Autoship' ||
    data.value?.LayoutType === 'Quickbuy' ||
    data.value?.LayoutType === 'WithoutNameCTA';
});

const initialQueryParams = () => {
  let query = '';
  const defaultFilters = props.block.Filters as any;
  Object.keys(defaultFilters).forEach((key: any) => {
    if (defaultFilters[key]?.length) {
      query += `&${key}=${defaultFilters[key]}`;
    }
  });
  return query;
};

const filterQueryParams = () => {
  let query = '';
  filters.value.forEach((filter: IFilter) => {
    const selectedForFilter: string[] = [];
    filter.values.forEach((value: IFilterValue) => {
      if (value.isSelected) {
        selectedForFilter.push(value.id);
      }
    });
    if (selectedForFilter.length) {
      query += `&${filter.name}=${selectedForFilter.join(',')}`;
    }
  });
  return query;
};

const fetchProducts = async(initialRequest = true) => {
  const { currentMarket, language } = config;
  loading.value = true;

  let append = '';
  if (props.isSample) {
    append = `&purchaseType=${PurchaseType.Sampling}`;
  } else if (data.value.LayoutType === 'Autoship') {
    append = `&purchaseType=${PurchaseType.Subscription}`;
  }
  if (data.value.Brands?.length) {
    append += `&Brands=${data.value.Brands}`;
  }
  if (data.value.UseBrandImage && data.value.LayoutType === 'Slider') {
    append += '&isBrandPage=true';
  }
  if (!data.value.Brands?.length) {
    append += productUrls.value;
  }
  append += initialRequest
    ? initialQueryParams()
    : filterQueryParams();
  if (sortBy.value && sortBy.value.length) {
    append += `&sortBy=${sortBy.value}`;
  }

  const query = `productitems?countryCode=${currentMarket?.countryCode}&language=${language}${append}`;
  const res = await apiGet<IProductItemsResult>(query);

  if (res && res.items) products.value = res.items;
  if (res && res.filters) filters.value = res.filters;

  loading.value = false;
};

// If products renders server side
if (shouldRenderServerSide) {
  await fetchProducts();
}

onMounted(async() => {
  if (!shouldRenderServerSide) {
    await fetchProducts();
  }
})

const toggleFilterSelected = (option: IFilterValue) => {
  filters.value.forEach((filter: IFilter) => {
    filter.values.forEach((value: IFilterValue) => {
      if (value.id === option.id) {
        value.isSelected = !value.isSelected;
      }
    });
  });
  fetchProducts(false);
};

const resetFilters = () => {
  filters.value.forEach((filter: IFilter) => {
    filter.values.forEach((value: IFilterValue) => {
      value.isSelected = false;
    });
  });
  fetchProducts(false);
};

const toggleFilter = () => {
  showFilter.value = !showFilter.value;
};

const isBrandImage = (product: any) => {
  return product.imageUrl.includes('?brandImage=true');
};

const productsValue = products.value as IProductItem[];
const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'ItemList',
  itemListElement: productsValue?.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index,
      url: item.url,
    };
  }),
} as any;

if (props.useSchemaProps) {
  useJsonld(jsonLd);
}
</script>
<style scoped>
.filter-swiper {
  @apply -mx-24 pr-32 md:ml-0 md:mr-0 md:pr-0;

  .swiper-wrapper {
    @apply pl-24 md:pl-0;
  }

  .swiper-slide {
    @apply w-auto;
  }
}

.select-sort-by {
  border-style: solid;
}
</style>
<style>
.products-slider {
  @apply px-24 md:px-56 lg:px-0;

  .swiper-pagination-progressbar {
    bottom: 0 !important;
    top: auto !important;
    right: 0 !important;
    left: 50% !important;
    width: 66.666% !important;
    overflow: hidden;
    @apply bg-grey400 rounded-full transform -translate-x-1/2 hidden lg:block;
  }

  .swiper-pagination-progressbar-fill {
    background-color: #0006 !important;
    @apply rounded-full;
  }

  .swiper-wrapper {
    align-items: normal !important;
    @apply w-auto !h-auto;
  }

  .swiper-slide {
    @apply w-11/12 sm:w-3/5 md:w-1/3 md:h-auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    transition: all 0.25s ease-in-out;
    top: 11.5rem;
    @apply opacity-0 bg-white rounded-full h-48 w-48 flex items-center justify-center;
    &.swiper-button-disabled {
      opacity: 0 !important;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    @apply text-grey800 text-sm font-bold;
  }

  .swiper-button-prev {
    @apply left-24;
    &::after {
      @apply pr-2;
    }
  }

  .swiper-button-next {
    @apply right-24;
    &::after {
      @apply pl-2;
    }
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      @apply opacity-100;
    }
  }

  .hide-buttons,
  .hide-pagination {
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination-progressbar {
      @apply !hidden;
    }
  }
}

.hide-buttons,
.hide-pagination {
  .swiper-button-next,
  .swiper-button-prev,
  .swiper-pagination-progressbar {
    @apply !hidden;
  }
}
</style>
